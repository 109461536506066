import { Component, OnInit } from '@angular/core';
import { Router, NavigationStart, NavigationCancel, NavigationEnd } from '@angular/router';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { filter } from 'rxjs/operators';
import { MainService } from './providers/main-service.service';
declare let $: any;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    providers: [
        Location, {
            provide: LocationStrategy,
            useClass: PathLocationStrategy
        }
    ]
})
export class AppComponent implements OnInit {
    location: any;
    routerSubscription: any;

    constructor(private router: Router,public service : MainService) {
       
    }

    ngOnInit(){

        this.recallJsFuntions();
        console.log("Sachin")
        // this.temp()
        this.service.loginModal.subscribe((res)=>{
            if(res){
                this.temp()
            }
            else{
               this.navigate()
            }
        })
       
    }
    //
    temp(){
        if(!localStorage.getItem('token')){
            $('#exampleModalCenter').modal({backdrop: 'static'})
          }
        
    }
    navigate(){
        $('#exampleModalCenter').modal('hide')
      
     }
     
    recallJsFuntions() {
        this.router.events
        .subscribe((event) => {
            if ( event instanceof NavigationStart ) {
                $('.preloader').fadeIn('slow');
            }
        });
        this.routerSubscription = this.router.events
        .pipe(filter(event => event instanceof NavigationEnd || event instanceof NavigationCancel))
        .subscribe(event => {
            $.getScript('../assets/js/main.js');
            $('.preloader').fadeOut('slow');
            this.location = this.router.url.substring(1);
            if(routersUrl.includes(this.location)){
                this.service.loginModal.next(true)
            }
            else{
                this.service.loginModal.next(false)
            }
            if (!(event instanceof NavigationEnd)) {
                return;
            }
            window.scrollTo(0, 0);
        });
    }
}
const routersUrl = ['tournaments','fixtures','membership-list','service-list','single-tournament','venue-list','evalution','leaderboard']